import "./slick/slick.min.js";
import "./acf-maps.js";

(function ($) {
  /* Toggle nav  */
  $(document).on("click", ".-js-navModal-toggle", function () {
    $(this).toggleClass("-open");
    $("body").toggleClass("-navModal-open");
    $(".-js-navModal").toggleClass("-collapsed");
  });

  $(".vacancy").each(function () {
    var toggle = $(this).find(".vacancy__heading");
    toggle.on("click", function () {
      $(this).parent().toggleClass("vacancy--active");
    });
  });

  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();
      document.querySelectorAll(".vacancy--active").forEach((activeElement) => {
        activeElement.classList.remove("vacancy--active");
      });
      const targetElement = document.querySelector(this.getAttribute("href"));
      targetElement.scrollIntoView({ behavior: "smooth" });
      targetElement.classList.toggle("vacancy--active");
    });
  });

  /* Carousel */

  /* Slick carousel */
  var $carousel = $(".-js-carousel");

  var carousel_settings = {
    slidesToShow: 1,
    sldesToScroll: 1,
    arrows: true,
    dots: true,
    waitForAnimate: true,
    swipe: true,
    touchMove: true,
    accessibility: true,
    nextArrow:
      '<button class="carousel__arrow -next"><span class="sr-only">Vorige quote</span></button>',
    prevArrow:
      '<button class="carousel__arrow -previous"><span class="sr-only">Volgende quote</span></button>',
    infinite: true,
    mobileFirst: true,
    centerMode: false,
  };

  // Init
  $carousel.slick(carousel_settings);

  // Reslick only if it's not slick()
  $(window).on("resize", function () {
    // if (!$usp_carousel.hasClass('slick-initialized')) {
    //   return $usp_carousel.slick(usp_carousel_settings);
    // }
  });
})(jQuery);
